*{
    overflow-anchor: none;
}

.page{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* background-color: green */
    padding-top: 1.4em;
}

.chat{
    width: 100%;
    /* height: calc(100% - 2.2em); */
    background-color: black;


}

#chat-window{
    margin: 0 auto;
    width: 90%;
    height: 75%;
    background-color: black;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;

    display: flex;
    flex-direction: column-reverse;
}


.user-header {
    width: 100%;
    border-bottom: 1px solid lightgrey;
}
.user-header > ul {
    list-style-type: none;
    color: white;

    display: flex;
    justify-content: center;

    padding: 5px;
}
.user-header > ul > li {
    padding: 5px;
}

.msg-list{
    display: flex;
    flex-direction: column-reverse;

}


.messages{
    /* padding: 0.5em;
    border-radius: 1em;
    margin: 0.5em;
    line-height: 1.1em;
    width: 15em; */
    background-color: dimgrey;
    color: white;

    padding: 20px;
	margin-bottom: 15px;
    border-radius: 10px;
    text-align: left;
    max-width: 85%;

    align-self: flex-start;

}

.black{
    border: 1px solid black;

}

.messages-right{
    /* padding: 0.5em;
    border-radius: 1em;
    margin: 0.5em;
    line-height: 1.1em;
    width: 15em; */
    background-color: royalblue;
    color: white;

    padding: 20px;
	margin-bottom: 15px;
    border-radius: 10px;
    text-align: left;
    max-width: 85%;
    align-self: flex-end;
}

.offline{
    height: 100%;
}

.meta{
    color: gainsboro;
    font-size: 15px;
	font-weight: bold;
	opacity: 0.7;
    margin-bottom: 7px;
    display: block;
    text-align: left;

}

.meta-right{
    color: lightgrey;
    font-size: 15px;
	font-weight: bold;
	opacity: 0.7;
    margin-bottom: 7px;
    display: block;
    text-align: left;

}

.typing{
    height: 1.1em;
    color: white;
 
}

.hide{
    display: none;
}

#handle{
    margin: 0 auto;
    width: 90%;
    border: 1px solid lightgray;
    padding: 5px;

    
}

.text-area {
    width: 90%;
    display: flex;
    justify-content: center;
    background-color: black;

}

#message{

    /* border: 1px solid lightgray; */
    border-top: none;

    margin: 0 auto;
    width: 95%;
    padding: 5px;

    color: white;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 10px;


}


textarea {
    resize: none;
}


#send{
    width: 24px;
    height: 80%;

    background-color: lime;
    color: black;
    border-radius: 10px;

    font-weight: bold;
    font-size: 15px;
    font-weight: bolder;

    padding: 5px;
    margin-left: 5px;

    cursor: pointer;

    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
    align-self: center;


}



.send-button:hover, .send-button:focus {
    color:white;
    background: #2196f3;
    box-shadow: 0 0 10px green, 0 0 40px green, 0 0 80px #8c9ca8;
}


  /* ----------
        COMPUTER SCREEN REGULAR 
----------------------*/
@media screen and (min-width: 768px) {

    /* CHAT PAGE: Only take up a portion of the screen so that the links sidebar can appear */
    .chat{
        width: 68%;
        /* height: calc(100% - 2.2em); */
        background-color: black;
    
    
    }
}


