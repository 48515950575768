.sidebar{
    width: 0%;
    display: none;

}

.mobile{
    display: block;
    display: initial;
        width: 100%;
        float: left;
        justify-content: center;
        /* 2.2 is the height of the navbar. I added 1.2 extra to have some space */
        padding-top: 3.4em;
        /* height: calc(100% - 2.2em); */
        /* overflow-y: scroll;
        top: 0;
        bottom: 0; */
}

.sidebar > div{
    width: 100%;
    height: 80%;
    padding: 10px;
    overflow-y:auto;
    /* border: 1px solid red; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    


}
/* START OF LINKS DISPLAY */
.link-list{
    /* margin-top: 4em; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style-type: none;
    /* border: 1px dashed green; */

    width: 100%;
    height: 100%;

    padding: 5px;
}

/* SIDE BAR TITLE */
.sidebar_title {
    width: 100%;
    color: white;
    border-bottom: 1px solid grey;
    padding-bottom: 2px;
}

/* Message if no links have been made */
.link-list > p {
    width: 100%;
    padding-top: 10px;
}

.link-list .list_item{
    border: 5px solid rgb(32, 35, 48);
    background-color: grey;

    padding: 5px;
    border-radius: 10px;
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.link-list li section{
    display: flex;
    justify-content: flex-start;
    float: left;
    width: 55%;
}

.user_name {
    padding-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* min-width: 3.5em; */
    width: 70%;

}



.user_name{
    text-align: left;
}

.user_name:hover, .user_name:focus {
    text-decoration: underline;
}

.select-user {
    /* position: relative; */
    cursor: pointer;

}


/*  - - - - - - - - - - - - -
              MESSAGE ALERTS  
          - - - - - - - - - - - - -
*/

.badge {
    /* border: 1px solid blue;
    padding: 0;
    margin: 0;

    color: white;
    font-size: 9px;
    /* top: 0;
    left: 0; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* top: 0;
    height: auto; 
    width: 20px;
    height: 15px;
    content: '4';
    color: white;


    background-color: red;
    position: relative; */
    color: white;
  display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
  position: relative; /* All 'absolute'ly positioned elements are relative to this one */
  padding: 0px; /* no padding and no border so that this element is invisible pretty much */
    border: 0px;
}

/* .user_name{
    color: yellow;
} */
.Numberbadge{
    /* position: absolute;
    border: 1px solid yellow;
    padding: 0;
    margin: 0;
    content: 4;
    font-size: 10px; */
    background-color: #fa3e3e;
  border-radius: 10px;
  color: white;
 
  font-size: 10px;
  
  position: absolute; /* Position the badge within the relatively positioned button */
  border: 1px solid #fa3e3e;
  height: 12px;
  width: 20px;
 transform:  translateX(100%); 

}



/* END OF LINKS DISPLAY */

.chat-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style-type: none;

    width: 100%;

    padding: 5px;
}

.chat-list li{
    border: 1px solid rgb(32, 35, 48);
    background-color: grey;

    padding: 5px;
    border-radius: 10px;
    width: 100%;

    display: flex;
    justify-content: space-between;

    cursor: pointer;
}

.chat-list li section{
    display: flex;
    justify-content: flex-start;
    float: left;
}


/* temporary image */
.chat-list li section button{
    width: 25px;
    height: 25px;
    border-radius: 50%;
   
    border: 10px solid yellow;
    background-color: yellow;
    margin-right: 5px;
}


/* .search {
    padding: 5px;
    width: 100%;

    display: flex;
}

.search input{
    width: 90%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;

}

.search button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
} */


.chat-list li:hover, .chat-list li:focus {
    border: 1px solid white;
}

  /* ----------
        COMPUTER SCREEN REGULAR 
----------------------*/
@media screen and (min-width: 768px) {

    /* SIDEBAR PAGE: Only take up a small portion of the screen to show the links (left side) */
    .sidebar{
        display: block;
    display: initial;
        width: 32%;
        float: left;
        justify-content: center;
        /* 2.2 is the height of the navbar. I added 1.2 extra to have some space */
        padding-top: 3.4em;
        /* height: calc(100% - 2.2em); */
        /* overflow-y: scroll;
        top: 0;
        bottom: 0; */
        border-right: 2px solid grey;
    
    }
}