*, body{
  box-sizing: border-box;
  padding: 0;
  border: 0;  
  margin: 0;


}

html, body{
  height: 100%; 
  width: 100%;

}

body {
    font-size: 20px;
    line-height: 28px;

    text-align: center;

    background-color: rgb(32, 35, 48);

    
  }
  


/* -----------------------------
    have to make the root id 100% height
*/
#root{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
/*container */
.container{
  height: 100%;
  width: 100%;
  margin:0;
  padding:0;
}


/* 
    MAIN
*/
.App {
  width: 100%;
  height: 100%;

  padding: 15px;

  padding: 0;
  margin: 0;
}
