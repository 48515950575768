/* 1. Initialize */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root{
    --width-size: 200px;
    --button-width-size: 90px;
    --button-height-size: 40px;

}


/* 2. Page */
.page{
    height: 100%;
    width: 100%;
    background-color: rgb(32, 35, 48);
    border: 1px solid rgb(32, 35, 48);


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* 3. Heading */
#heading{
    text-align: center;
    margin-top: -25vh;
    /*margin-bottom: 5%; */
    
}

 #title{
    width: var(--width-size); /* 300px */
    animation: fills 1s ease forwards 2.5s;
    
} 

#title path:nth-child(1) {
    stroke-dasharray: 572.5;
    stroke-dashoffset: 572.5;
    animation: line-anim 1.5s ease forwards;
}

#title path:nth-child(2) {
    stroke-dasharray: 534.52;
    stroke-dashoffset: 534.52;
    animation: line-anim 1.5s ease forwards 0.3s;
}

#title path:nth-child(3) {
    stroke-dasharray: 490.29;
    stroke-dashoffset: 490.29;
    animation: line-anim 1.5s ease forwards 0.6s;
}

#title path:nth-child(4) {
    stroke-dasharray: 336.67;
    stroke-dashoffset: 336.67;
    animation: line-anim 1.5s ease forwards 0.9s;
}

#title path:nth-child(5) {
    stroke-dasharray: 495.22;
    stroke-dashoffset: 495.22;
    animation: line-anim 1.5s ease forwards 1.2s;
}

#title path:nth-child(6) {
    stroke-dasharray: 482.58;
    stroke-dashoffset: 482.58;
    animation: line-anim 1.5s ease forwards 1.5s;
}

#title path:nth-child(7) {
    stroke-dasharray: 482.58;
    stroke-dashoffset: 482.58;
    animation: line-anim 1.5s ease forwards 1.8s;
}

/* 4. Buttons */

.buttons{
    width: var(--width-size);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 5vw auto;

    width: 100%;

}

.buttons button, .buttons a{
    width: var(--button-width-size);
    height: var(--button-height-size);

    font-size: 18px;

    padding: 10px;
    cursor: default;
    

    /* background-color: white; */
    text-decoration: none;

    border-radius: 5px;
    
    opacity: 0;
    animation: appear 4s ease forwards 2s;

}

button:hover, button:focus, a:hover, a:focus{
    color:white;
    background: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #8c9ca8;
}

/* google */
.google {
    padding: 10px;
    font-weight: bold;
    background-color: tomato;
    color: white;
    font-size: 18px;
}


.google:focus, .google:hover{
    cursor: pointer;

    color:white;
    background: tomato;
    box-shadow: 0 0 10px tomato, 0 0 40px tomato, 0 0 80px #8c9ca8;
}

/* TWITCH */
.twitch {
    padding: 10px;
    font-weight: bold;
    background-color: mediumorchid;
    color: white;
    font-size: 18px;
}

.twitch:focus, .twitch:hover{
    cursor: pointer;

    color:white;
    background: mediumorchid;
    box-shadow: 0 0 10px mediumorchid, 0 0 40px mediumorchid, 0 0 80px #8c9ca8;
}

/* linkedin */
.linkedin {
    padding: 10px;
    font-weight: bold;
    background-color: #2196f3;
    color: white;
    font-size: 18px;
}

.linkedin:focus, .linkedin:hover{
    cursor: pointer;

    color:white;
    background: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #8c9ca8;
}

/* github */
.github {
    padding: 10px;
    font-weight: bold;
    background-color: grey;
    color: white;
    font-size: 18px;
}

.github:focus, .github:hover{
    cursor: pointer;

    color:white;
    background: grey;
    box-shadow: 0 0 10px grey, 0 0 40px grey, 0 0 80px #8c9ca8;
}

.oauth-btns {
    width: 100%;
}

.oauth {
    margin: 5px;
}
/* 5. Down bar */
    .down-bar{
        position: absolute;
        top: 95vh;
        left: 0;

        width: 100%;
        height: 5vh;
        
        background-color: rgb(255,255,255,.7);

        opacity: 0;
        animation: appear 4s ease forwards 3s;
    
    }

    .down-bar, .down-bar:visited{
        text-decoration: none;
        color: rgb(32, 35, 48);
    }

    .down-bar:hover, .down-bar:focus{
        color:white;
        background: #2196f3;
        box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #8c9ca8;
        cursor: pointer;
    }

/* 6. Landing Info */
#hidden{
    display:none;
}

#landing-info{
    position: relative;
    top: 80vh;
}

    

/* MEDIA QUERIES */

 @media only screen and (min-width: 700px) {
    #title{
        width: 500px;
    }
}

@media only screen and (min-width: 1024px) {
    #title{
        width: 600px;
    }
}



/* Animations */
@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fills{
    from{
        fill: transparent;
    }
    to{
        fill: rgb(255,255,255,.7);
    }
}

@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
        cursor: pointer;
    }
}


    /* ----------
        COMPUTER SCREEN REGULAR 
----------------------*/
@media screen and (min-width: 768px) {
    :root{
        --width-size: 300px;
        --button-width-size: 90px;
        --button-height-size: 50px;
    
    }
}