.app_nav{
    box-sizing: border-box;
    height: 2.2em;
    /* max-height: 60px; */
    width: 100%;

    padding: 5px;

    background-color: #383e42;
    color: white;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0;

}

.app_nav > ul {
    font-size: 1rem;
    cursor: pointer;
    display: flex;
}

.app_nav > ul:hover, .app_nav > ul:focus {
  color: lightblue;
}

.app_nav ul li {
    list-style-type: none;
}

.app_nav ul li:nth-child(1) {
    margin-right: 10px;
}

.app_nav > ul > li > h3 {
  display: none;
}

.profile{
    border: 2px solid transparent;
}

.dropdown {
    cursor: pointer;
}

.dropdown-content {
    /* display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 8px;
    margin-top: 5px; */
    display: none;
    position: absolute;
    background-color: #f9f9f9;

    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 5px;
    border-radius: 5px;
    cursor: pointer;

  }
  
  .dropdown-content a {
    /* color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left; */
    float: none;
    color: black;
    padding: 5px 20px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid grey;
  }

  .dropdown-content a:first-child{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .dropdown-content a:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .dropdown-content a:hover, .dropdown-content a:focus {background-color: #ddd;}
  .dropdown:hover .dropdown-content, .dropdown:focus .dropdown-content {display: block;}

    /* ----------
        COMPUTER SCREEN REGULAR 
----------------------*/
@media screen and (min-width: 768px) {
  .app_nav > ul > li > h3 {
    display: block;
    display: initial;
  }
}
  