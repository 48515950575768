.page{
    color: white;
}

.alt-back{
    height: 50px;
    width: 100%;

    background-color: #383e42; 

    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.alt-back-button{
    color: white;

    background-color: #383e42; 
    /* text-align: start; */
    padding: 6px;
    /* to center the back icon */
    padding-right: 8px; 


    font-weight: bold;
    font-size: 26px;
}

.alt-back-button:focus, .alt-back-button:hover{
    color: grey;
    cursor: pointer;

}


.user-info-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    
}

.user-info-header h1{
    font-size: 50px;
}

.user-info-form{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

 .section-form{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.section-form-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.section-form-inner > label {
    margin-bottom: 10px;
}

.section-form-inner > input {
    width: 100%;
    border: 1px solid black;
    max-width: 150px;
    padding: 2px;
}

.section-form-inner > select {
    width: 100%;
    border: 1px solid black;
    max-width: 150px;
    padding: 2px;
    cursor: pointer;
}



.demo-info{
    font-size: 13px;
    color: white;
  }

  .submit {
    padding: 10px;
    font-weight: bold;
    background-color: grey;
    color: white;
    font-size: 18px;
}

.submit:focus, .submit:hover{
    cursor: pointer;

    color:white;
    background: grey;
    box-shadow: 0 0 10px grey, 0 0 40px grey, 0 0 80px #8c9ca8;
}


.google {
    padding: 10px;
    font-weight: bold;
    background-color: tomato;
    color: white;
    font-size: 18px;
}

.google:focus, .google:hover{
    cursor: pointer;

    color:white;
    background: tomato;
    box-shadow: 0 0 10px tomato, 0 0 40px tomato, 0 0 80px #8c9ca8;
}
/*---------------- RESPONSIVE LAYOUT ----------------*/


    /* ----------
            COMPUTER SCREEN REGULAR 
    ----------------------*/
    @media screen and (min-width: 768px) {
      
        .alt-back{
            height: 60px;
        }

        .user-info-header h1{
            font-size: 60px;
        }
      

        .section-form{
            font-size: 22px;
        }

        .section-form-inner > input {
            width: 100%;
            max-width: 250px;
            font-size: 18px;
        }

        .section-form-inner > select {
            width: 100%;
            max-width: 250px;
            font-size: 18px;
        }

        
       
        .demo-info{
            font-size: 16px;
          }
  
    }
      /* ---------------------- 
            COMPUTER SCREEN LARGE 
      ----------------------*/
    @media screen and (min-width: 1024px) {
        .alt-back{
            display: none;
        }
        .user-info-header h1{
            font-size: 80px;
        }
    
        .section-form{
            width: 90%;
            font-size: 30px;

            display: flex;
            flex-direction: column;
            justify-content: center !important;
            align-items: flex-start;
        
            margin-bottom: 20px;
        }
        
        .section-form-inner {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

        }

        .section-form-inner > label {
            width: 35%;
            text-align: end;
        }
        .section-form-inner > input {
            width: 65%;
            max-width: 330px;
            border: 1px solid black;
            font-size: 22px;
        }

        .section-form-inner > select {
            width: 65%;
            max-width: 330px;
            border: 1px solid black;
            font-size: 22px;
        }


        .demo-info{
            font-size: 18px;
          }
        
          
}

/* ---------------------- 
            COMPUTER SCREEN LARGER 
      ----------------------*/
      @media screen and (min-width: 1440px) {
        .section-form-inner > label {
            width: 35%;
            text-align: end;
        }
        .section-form-inner > input {
            width: 65%;
            max-width: 330px;
            border: 1px solid grey;
        }
        .section-form-inner > select {
            width: 65%;
            max-width: 330px;
            border: 1px solid grey;
        }
      }

      /* ---------------------- 
            COMPUTER SCREEN LARGEST 
      ----------------------*/
      @media screen and (min-width: 1440px) {
        .section-form-inner > label {
            width: 40%;
            text-align: end;
        }
        .section-form-inner > input {
            width: 60%;
            max-width: 330px;
            border: 1px solid grey;
        }
        .section-form-inner > select {
            width: 60%;
            max-width: 330px;
            border: 1px solid grey;
        }
      }
